















































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
  goto(path: string) {
    if (path === this.$route.path) return;
    this.$router.push(path);
  }
}
