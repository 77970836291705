






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class NavigationBar extends Vue {
  logout() {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("termini");
    this.$router.push("/login");
  }
  Abbonamento() {
    this.$router.push("/subscriptions");
  }
  goto(path: string) {
    if (path === this.$route.path) return;
    this.$router.push(path);
  }
}
