














import NavigationBar from "@/components/NavigationBar.vue";
import Sidebar from "@/components/Sidebar.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {
    NavigationBar,
    Sidebar
  }
})
export default class DashboardLayout extends Vue {
  logout() {
    this.$router.push("/login");
  }

  goto(path: string) {
    if (path === this.$route.path) return;
    this.$router.push(path);
  }
}
